<template>
  <div class="nav_">
    <van-nav-bar :style="{background: backColor,border:backColor}">
        <template #title>
            <span :style="{color: textColor}">{{title}}</span>
        </template>
        <template #left>
            <van-image class="icon" fit="contain" :src="icon" @click="goback" />
        </template>
        <template #right v-if="rightbg">
            <img class="right" :src="rightbg" @click="goSearch" />
        </template>
    </van-nav-bar>

  </div>
</template>

<script>

  export default {
    name: 'navBar',
    props: {
      icon: String,
      textColor:String,
      backColor:String,
      rightbg:String,
      rightColor:String,
      backClick:Number
    },
    data() {
      return {
        
      };
    },
    computed:{
        title(){
            return this.$route.meta.title
        },
    },
    methods: {
        goback(){
          if(this.backClick){
            this.$router.go(-2)
          }else{
            this.$router.go(-1)
          }
          
        },
        goSearch(){
          this.$router.push({path:'/search'})
        }
    },
  };
</script>

<style>
.van-nav-bar::after {
    border: none;
}
</style>
<style scoped>
.icon{
    width: 20px;
    height: 35px;
}
.right{
  width: 45px;
}
.nav_{
  margin-top: 64px;
}
</style>