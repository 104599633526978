<template>
  <div>
    <nav-bar class="title_top" :icon="'img/backb.png'" :textColor="'#000000'" :backColor="'none'" />
    <div style="padding:0 20px;">
        <p>1.为保障光恋、苃缘相关功能的实现与应用安全稳定的运行，我们可能会接入由合作方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现相关目的。</p>
        <p>2.我们会对获取信息的合作方的代码进行严格的安全监测，以保护数据安全。</p>
        <p>3.我们对接入的相关合作方在目录中列明，合作方具体的处理情况请参见合作方的隐私政策或服务协议。</p>
        <p>4.请注意，合作方的代码可能因为其版本升级、策略调整等原因导致数据处理类型存在一定变化，请以其公示的官方说明为准。</p>
        <div class="title">第三方SDK目录</div>
        <p style="font-weight:bold; text-indent: unset;">Alipay（支付宝） SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、MEID、IMSI、Android ID&iOS 如IDFV、IDFA、Serial、ICCID），MAC地址，IP 地址，WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：帮助用户在应用内使用支付宝</p>
        <p style="text-indent: unset;">使用场景：在用户使用支付宝支付时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">支付宝（杭州）信息技术有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://render.alipay.com/p/f/fd-iwwyijeh/index.html" target="_blank">https://render.alipay.com/p/f/fd-iwwyijeh/index.html</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://docs.open.alipay.com/54" target="_blank">https://docs.open.alipay.com/54</a></p>
        <p style="font-weight:bold; text-indent: unset;">小米PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、MEID、OAID、Serial、uuid）、WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在小米手机终端推送消息时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">北京小米移动软件有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://dev.mi.com/console/appservice/push.html" target="_blank">https://dev.mi.com/console/appservice/push.html</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://dev.mi.com/console/doc/detail?pId=1822" target="_blank">https://dev.mi.com/console/doc/detail?pId=1822</a></p>
        <p style="font-weight:bold; text-indent: unset;">魅族PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Serial）、MAC地址、IP地址、WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在魅族手机终端推送消息时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">珠海市魅族通讯设备有限公司及其关联方（简称魅族）、与魅族建立合作的合作方公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://open.flyme.cn/open-web/views/push.html" target="_blank">https://open.flyme.cn/open-web/views/push.html</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://www.meizu.com/legal.html" target="_blank">https://www.meizu.com/legal.html</a></p>
        <p style="font-weight:bold; text-indent: unset;">华为PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：应用匿名标识（AAID），应用Token，应用列表</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在华为手机终端推送消息时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">华为软件技术有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://developer.huawei.com/consumer/cn/doc/app/20213" target="_blank">https://developer.huawei.com/consumer/cn/doc/app/20213</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021" target="_blank">https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/privacy-statement-0000001050042021</a></p>
        <p style="font-weight:bold; text-indent: unset;">荣耀PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：应用匿名标识 (AAID)，应用Token</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在荣耀手机终端推送消息时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">荣耀终端有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://developer.hihonor.com/cn/doc/guides/100226" target="_blank">https://developer.hihonor.com/cn/doc/guides/100226</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://www.hihonor.com/cn/privacy/privacy-policy/" target="_blank">https://www.hihonor.com/cn/privacy/privacy-policy/</a></p>
        <p style="font-weight:bold; text-indent: unset;">OPPO PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、SIM卡序列号、IMSI）、应用信息（如应用包名、版本号和运行状态）、网络信息（如IP或域名连接结果，当前网络类型）</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在OPPO手机终端推送消息时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">广东欢太科技有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://open.oppomobile.com/wiki/doc#id=10194" target="_blank">https://open.oppomobile.com/wiki/doc#id=10194</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://open.oppomobile.com/wiki/doc#id=10288" target="_blank">https://open.oppomobile.com/wiki/doc#id=10288</a></p>
        <p style="font-weight:bold; text-indent: unset;">VIVO PUSH</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备信息</p>
        <p style="text-indent: unset;">使用目的：推送消息</p>
        <p style="text-indent: unset;">使用场景：在VIVO手机终端推送消息时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">广东天宸网络科技有限公司及将来受让运营vivo开放平台的公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://dev.vivo.com.cn/documentCenter/doc/366" target="_blank">https://dev.vivo.com.cn/documentCenter/doc/366</a></p>
        <p style="font-weight:bold; text-indent: unset;">高德地图SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、MEID、IMSI、OAID、Serial）、MAC地址、IP 地址、位置信息（如GPS）、WLAN接入点）</p>
        <p style="text-indent: unset;">使用目的：帮助用户发布信息时定位位置</p>
        <p style="text-indent: unset;">使用场景：用户使用定位相关功能时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">高德软件有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://lbs.amap.com/" target="_blank">https://lbs.amap.com/</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://lbs.amap.com/pages/privacy/" target="_blank">https://lbs.amap.com/pages/privacy/</a></p>
        <p style="font-weight:bold; text-indent: unset;">友盟umengSDK 推送</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、MEID、IMSI、Android ID&iOS 如IDFV、IDFA、Serial），WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：推送信息</p>
        <p style="text-indent: unset;">使用场景：在推送消息时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">北京锐讯灵通科技有限公司、友盟同欣（北京）科技有限公司、浙江阿里巴巴云计算有限公司、北京缔元信互联网数据技术有限公司等</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://developer.umeng.com/docs/147377/detail/210024" target="_blank">https://developer.umeng.com/docs/147377/detail/210024</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://developer.umeng.com/docs/147377/detail/209997" target="_blank">https://developer.umeng.com/docs/147377/detail/209997</a></p>
        <p style="font-weight:bold; text-indent: unset;">微信SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、Serial），MAC地址、WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：支持微信登录和分享</p>
        <p style="text-indent: unset;">使用场景：在用户使用微信登录或者分享时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">深圳市腾讯计算机系统有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://open.weixin.qq.com/" target="_blank">https://open.weixin.qq.com/</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy" target="_blank">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p style="font-weight:bold; text-indent: unset;">微信支付SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、Serial），MAC地址、WLAN接入点</p>
        <p style="text-indent: unset;">使用目的：支持微信支付</p>
        <p style="text-indent: unset;">使用场景：在用户使用微信支付时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">深圳市腾讯计算机系统有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://open.weixin.qq.com/" target="_blank">https://open.weixin.qq.com/</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy" target="_blank">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a></p>
        <p style="font-weight:bold; text-indent: unset;">QQ SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、IMSI、MEID）、MAC地址、IP 地址、位置信息（如GPS）</p>
        <p style="text-indent: unset;">使用目的：QQ登录/分享功能</p>
        <p style="text-indent: unset;">使用场景：在用户使用QQ登录/分享时使用</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">深圳市腾讯计算机系统有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://wiki.connect.qq.com/%e5%bc%80%e5%8f%91%e8%80%85%e5%8d%8f%e8%ae%ae" target="_blank">https://wiki.connect.qq.com/%e5%bc%80%e5%8f%91%e8%80%85%e5%8d%8f%e8%ae%ae</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e" target="_blank">https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e</a></p>
        <p style="font-weight:bold; text-indent: unset;">腾讯Bugly SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、IMSI、MEID）、MAC地址、IP 地址、位置信息（如GPS）</p>
        <p style="text-indent: unset;">使用目的：异常上报</p>
        <p style="text-indent: unset;">使用场景：在App异常闪退等情况下收集上报，协助快速发现并解决异常</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">深圳市腾讯计算机系统有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://bugly.qq.com/v2/index" target="_blank">https://bugly.qq.com/v2/index</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56" target="_blank">https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56</a></p>
        <p style="font-weight:bold; text-indent: unset;">网易云信 SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、IMSI、MEID）、MAC地址、IP 地址、位置信息（如GPS）</p>
        <p style="text-indent: unset;">使用目的：信令体系以及IM及时通讯</p>
        <p style="text-indent: unset;">使用场景：实现私聊、群聊、聊天室等通讯功能</p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">杭州网易质云科技有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://yunxin.163.com/im" target="_blank">https://yunxin.163.com/im</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://yunxin.163.com/clauses?serviceType=3 " target="_blank">https://yunxin.163.com/clauses?serviceType=3 </a></p>
        <p style="font-weight:bold; text-indent: unset;">agora 声网 SDK</p>
        <p style="text-indent: unset;">涉及的个人信息类型：设备标识符（如IMEI、Android ID&iOS 如IDFV、IDFA、IMSI、MEID）、MAC地址、IP 地址、位置信息（如GPS）</p>
        <p style="text-indent: unset;">使用目的：实现直播间推拉流</p>
        <p style="text-indent: unset;">使用场景：互动直播，语音连麦 </p>
        <p style="text-indent: unset;">第三方主体：<span style="font-weight:bold;">上海兆言网络科技有限公司</span></p>
        <p style="text-indent: unset;">收集方式：SDK采集</p>
        <p style="text-indent: unset;">官网链接：<a href="https://www.agora.io/cn/?utm_campaign=pinzhuan&utm_medium=cpc&utm_content=pinzhuan&utm_term=pinzhuan" target="_blank">https://www.agora.io/cn/?utm_campaign=pinzhuan&utm_medium=cpc&utm_content=pinzhuan&utm_term=pinzhuan</a></p>
        <p style="text-indent: unset;">隐私政策：<a href="https://www.agora.io/cn/privacy-policy" target="_blank">https://www.agora.io/cn/privacy-policy</a></p>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/navBar.vue';
export default {
  name: 'thirdShare',
  components: {
    NavBar
  },
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
    word-break: break-all;
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
    text-align: center;
}
.hint{
  margin: 30px 0 50px;
}
.hint p{
  font-size: 32px;
  color: #000;
  font-weight: bold;
  margin: 10px 0;
}
.title_top{
    position: relative;
    padding-top: 65px;
}
</style>
